.researchBody {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    width: 100%;
    height: 100%;
    .ant-table {
        min-height: calc(100vh - 550px);
    }
    .headerContent {
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 4px 15px rgba(45, 64, 101, 0.08);
        .backBtn {
            width: 25%;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            padding-left: 1%;
            cursor: pointer;
            img {
                width: 30px;
                height: 30px;
            }
        }
        .researchTitle {
            width: 50%;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            color: #2E2C41;
            opacity: 0.99;
        }
    }
    .headerContent div:nth-child(3) {
        width: 25%;
    }
    .bodyContent {
        padding: 1% 10% 0 10%;
        min-height: calc(~"100vh - 140px");
        min-width: 1200px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background-color: #F6F7FB;
        .researchInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 115px;
            width: 100%;
            margin: 1% 0;
            background-color: #fff;
            .researchInfo_left {
                display: flex;
                width: 33%;
                padding-left: 3%;
                img {
                    width: 127px;
                    height: 100px;
                }
                .researchInfo_titleDate {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 3%;
                    width: 100%;
                }
                .researchInfo_title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                    opacity: 0.99;
                    .infoTitle {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 70%;
                    }
                    .researchtype {
                        width: 34px;
                        height: 20px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 9px;
                        font-size: 12px;
                        font-weight: normal;
                        margin-left: 2%;
                    }
                }
                .researchInfo_date {
                    font-size: 14px;
                    color: #888
                }
            }
            .researchInfo_center {
                display: flex;
                width: 33%;
                flex-direction: column;
                align-items: center;
                color: #333;
                font-size: 12px;
                font-weight: bold;
                .count {
                    font-size: 25px;
                    color: #3E78ED
                }
            }
            .researchInfo_right {
                display: flex;
                width: 33%;
                justify-content: flex-end;
                padding-right: 3%;
                button {
                    margin-right: 3%;
                }
            }
        }
        .tableCondition {
            display: flex;
            align-items: center;
            background-color: #fff;
            line-height: 72px;
            margin-bottom: 1px;
            .tc_left {
                margin-left: 2%;
            }
            .tc_center {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .tc_right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 4%;
            }
            span {
                color: #333;
                font-size: 14px;
                margin-right: 3%;
            }
        }
        .table {
            // height: calc(~"100vh - 390px");
            padding: 1%;
            background-color: #fff;
            .tableCount {
                text-align: right;
                color: #888;
                font-size: 14px;
                margin-bottom: 1%;
            }
            .tableRelated {
                margin: auto;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 150px;
            }
            .pagination {
                display: flex;
                justify-content: flex-end;
                margin-top: 2%;
            }
            .ant-checkbox-wrapper {
                margin-left: 36px;
            }
            .vertical_j {
                justify-content: center;
            }
        }
    }
    .footerContent {
        display: flex;
        align-items: center;
        padding-left: 12%;
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        box-shadow: 0px -4px 15px rgba(13, 23, 49, 0.04);
        button {
            margin-left: 1%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .researchBody {
        .bodyContent {
            padding: 1% 5% 0 5%;
        }
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;