.EditContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #F6F7FB;
    >.e_left {
        width: 256px;
        background: #fff;
        height: 100%;
        .ww::-webkit-scrollbar {
            display: none;
        }
        .ww {
            overflow: auto;
            width: 256px;
            background: #fff;
            height: calc(100vh - 28px);
            height: 100%;
            padding: 22px;
            position: fixed;
        }
        .addqustion {
            >.h {
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                padding-bottom: 10px;
            }
            >.text {
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: #7F8693;
            }
            .btns_j {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                .btns_j_pragraph {
                    width: 102px;
                    height: 32px;
                    background: #FAFAFA;
                    border: 2px solid #EDEDED;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0 12px;
                    margin: 7px 0;
                    cursor: move;
                    >span {
                        padding-right: 3px;
                        >svg {
                            position: relative;
                            top: 2px;
                        }
                    }
                }
                .btns_j_pragraph:hover {
                    border-color: #3E78ED;
                    color: #3E78ED;
                    svg path {
                        fill: #3E78ED;
                    }
                }
            }
        }
        .comment {
            >.h {
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                padding-bottom: 10px;
                padding-top: 10px;
            }
            >.text {
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: #7F8693;
            }
        }
        .b_text {
            padding-top: 20px;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 20px;
            text-align: justify;
            color: #333333;
            cursor: pointer;
            .pragraph {
                .btns_j_pragraph {
                    width: 102px;
                    height: 32px;
                    background: #FAFAFA;
                    border: 2px solid #EDEDED;
                    box-sizing: border-box;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0 12px;
                    margin: 7px 0;
                    cursor: move;
                    >span {
                        padding-right: 3px;
                        >svg {
                            position: relative;
                            top: 2px;
                        }
                    }
                }
                .btns_j_pragraph:hover {
                    border-color: #3E78ED;
                    color: #3E78ED;
                    svg path {
                        fill: #3E78ED;
                    }
                }
            }
        }
        .common-topic {
            // position: absolute;
            width: 216px;
            height: 0px;
            left: 20px;
            top: 440px;
            border: 1px solid #FAFAFA;
            .common-style {
                padding: 10px 0;
                font-size: 16px;
            }
            .common-font {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: #7F8693;
            }
            .ant-collapse {
                border: none;
            }
            .common-navigation {
                background: #fff;
                cursor: pointer;
                margin-top: 20px;
                width: 100%;
                padding: 0px;
                .common-svg {
                    padding: 0 10px 0 0;
                }
                .index-color {
                    border: 1px solid #3E78ED !important;
                    color: #3E78ED;
                }
                .common-list {
                    width: 210px;
                    margin: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 5px;
                    padding-left: 10px;
                    border: 1px solid #fff;
                    .list-type {
                        display: inline-block;
                        height: 32px;
                        text-align: left;
                        line-height: 32px;
                        &:first-child {
                            flex: 1;
                        }
                        &:last-child {
                            padding-right: 10px;
                        }
                    }
                    #list-type {
                        padding-left: 3px;
                    }
                    #list-type1 {
                        text-align: center;
                    }
                }
                .ant-collapse-content>.ant-collapse-content-box {
                    padding: 0
                }
            }
        } // .common_use {
        //     >.h {
        //         font-weight: 500;
        //         font-size: 16px;
        //         color: #333333;
        //        padding-bottom: 10px;
        //     }>.text {
        //         font-family: Noto Sans SC;
        //         font-style: normal;
        //         font-weight: normal;
        //         font-size: 12px;
        //         line-height: 17px;
        //         color: #7F8693;
        //     }
        // }
    }
    >.e_center {
        flex: 1;
        height: 100%;
        background: #F6F7FB;
        margin-top: 20px;
        >.center_con {
            background: #F6F7FB;
            padding: 0 20px;
            .section {
                background: #fff;
                height: 136px;
                padding: 34px;
                .e_title_wrap {
                    line-height: 34px;
                    >.title {
                        >span {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            color: #333333;
                        }
                        .icon {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 17px;
                            color: #7F8693;
                            padding-left: 10px;
                            cursor: pointer;
                        }
                    }
                    >.title:hover {
                        .icon {
                            color: #3E78ED;
                        }
                        svg path {
                            fill: #3E78ED;
                        }
                    }
                }
            }
            .display {
                width: 100%;
                height: 120px;
                border: 1px dashed #3E78ED;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 20px;
                >div {
                    display: flex;
                    align-items: center;
                    margin: 0 auto;
                    justify-content: center;
                    >span {
                        display: flex;
                        align-items: center;
                        margin: 10px;
                    }
                    >.icon {
                        margin: 0;
                    }
                }
            }
            .questionList {
                padding: 20px 0;
                .cur_action {
                    padding: 15px 10px;
                    margin-bottom: 30px;
                    background: #FFFFFF;
                    box-sizing: border-box;
                    box-shadow: 0px 0px 10px rgba(30, 53, 99, 0.1);
                    border-radius: 5px;
                }
                .cur_action:hover {
                    .SingleChoice .SingleChoice_title .QAction {
                        width: 331px;
                        display: flex;
                    }
                }
                .cur_action_index_active {
                    background: #FFFFFF;
                    /* 蓝色/ */
                    border: 2px solid #3E78ED;
                    box-sizing: border-box;
                    box-shadow: 0px 0px 15px rgba(30, 53, 99, 0.15);
                    border-radius: 5px;
                }
            }
            .footyer_bottom {
                background: #fff;
                .e_title_wrap {
                    padding: 20px;
                    line-height: 34px;
                    >.title {
                        >span {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 18px;
                            color: #333333;
                        }
                        .icon {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 17px;
                            color: #7F8693;
                            padding-left: 10px;
                            cursor: pointer;
                        }
                    }
                    >.title:hover {
                        .icon {
                            color: #3E78ED;
                        }
                        svg path {
                            fill: #3E78ED;
                        }
                    }
                }
                .submit_btn_question {
                    padding: 10px 40px;
                    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
                }
            }
        }
    }
    >.e_right {
        width: 240px;
        background: #fff;
        height: 100%;
    }
    .display_radio_checkbox {
        .ant-radio-inner {
            border-radius: 0;
        }
    }
    .ant-radio-inner::after {
        background-color: none;
    }
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 12px;
    overflow: hidden;
    background: #FAFAFA;
    border: 1px solid #EDEDED;
    border-radius: 2px;
}

.SingleChoice {
    .SingleChoice_title {
        display: flex;
        height: 34px;
        align-items: center;
        >span {
            margin-right: 10px;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #000000;
        }
        .SingleChoice_text {
            height: 100%;
            flex: 1;
            >span {
                flex: 1;
                height: 100%;
            }
            >.SingleChoice_text_click {
                height: 100%;
                flex: 1;
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                cursor: pointer;
            }
            .SingleChoice_text_click:hover {
                border: 1px dotted #888888;
            }
        }
        .QAction {
            width: 260px;
            display: none;
            align-items: center;
            .QActionitem {
                flex: 1;
                margin: 0 10px;
                cursor: pointer;
                >span {
                    min-width: 45px;
                    ;
                    display: flex;
                    align-items: center;
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: #7F8693;
                    >svg {
                        margin: 0 3px;
                    }
                }
                >span:hover {
                    color: #3E78ED;
                    >svg path {
                        fill: #3E78ED;
                    }
                }
                .ant-upload {
                    font-size: 12px;
                    color: #7F8693;
                }
            }
        }
    }
    .SingleChoice_img_list_wrap {
        width: 100%;
        max-width: 520px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px;
        >div {
            width: 70px;
            height: 70px;
            border-radius: 4px;
            margin: 10px;
            position: relative;
            overflow: hidden;
            >img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            .markSingleChoice_img_list_wrap {
                position: absolute;
                bottom: 0;
                background: rgba(0, 0, 0, 0.6);
                width: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                height: 30px;
                color: #fff;
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                cursor: pointer;
            }
        }
        >div:hover {
            .markSingleChoice_img_list_wrap {
                display: flex;
            }
        }
    }
    .SingleChoice_main {
        display: flex;
        .SingleChoicr_wrap {
            width: 100%;
            flex: 1;
            .ant-radio-wrapper {
                width: 100%;
                display: flex;
                height: 34px;
                padding-left: 20px;
                align-items: center;
            }
            .ant-radio-wrapper>span:nth-child(2) {
                flex: 1;
                display: flex;
                flex-direction: column;
                >.radio_hover_aaaa {
                    flex: 1;
                    display: flex;
                    >.option_item_text {
                        flex: 1;
                        display: flex;
                        >i {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        >span {
                            padding-left: 10px;
                        }
                    }
                    >.option_item_text>div {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        padding-left: 10px;
                        border: 1px dashed #fff;
                    }
                    >.option_item_text>div:hover {
                        border: 1px dashed #888888;
                        border-radius: 5px;
                    }
                    >.option_item_action {
                        display: none; // display: flex;
                        margin: 0 10px;
                        .Choiceoption {
                            flex: 1;
                            display: flex;
                            width: 240px;
                            justify-content: space-around;
                            align-items: center;
                            padding-right: 45px;
                            .Choiceoptionitem {
                                >span {
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    color: #888888;
                                    >svg {
                                        margin: 0 4px;
                                    }
                                }
                                >span:hover {
                                    color: #3E78ED;
                                    >svg path {
                                        fill: #3E78ED;
                                    }
                                }
                            }
                        }
                    }
                }
                .img_radio_hover {}
            }
            .ant-radio-wrapper>span:nth-child(2):hover {
                .option_item_action {
                    display: flex;
                }
            }
            .img_radio_hover {
                display: flex;
                align-items: center;
                padding-left: 20px;
                width: 100%;
                max-width: 520px;
                display: flex;
                flex-wrap: wrap;
                padding: 0 30px;
                >div {
                    width: 70px;
                    height: 70px;
                    border-radius: 4px;
                    margin: 10px;
                    position: relative;
                    overflow: hidden;
                    >img {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                    .markSingleChoice_img_list_wrap {
                        position: absolute;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.6);
                        width: 100%;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        color: #fff;
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
                >div:hover {
                    .markSingleChoice_img_list_wrap {
                        display: flex;
                    }
                }
            }
        }
    }
    .start_tab_main_wrap {
        width: 100%;
        display: flex; // height: 240px;
        padding: 10px 10px 10px 30px;
        .start_tab_main {
            width: 100%;
            height: 100%;
            .title_tabs {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                border: 1px solid #EEEEEE;
                border-bottom: none;
                .left_t11 {
                    flex: 3;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    >div {
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        border: 1px solid #EEEEEE;
                        height: 100%;
                        width: 120px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        background: #FAFAFA;
                    }
                    >div.active {
                        background: #fff;
                        border: none;
                        transform: scale(1.01);
                        border-top: 4px solid #3E78ED;
                        box-sizing: border-box;
                    }
                }
                .right_t11 {
                    flex: 1;
                    padding-right: 100px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .start_option_wrap {
                width: 100%;
                .start_option_content {
                    width: 100%;
                    display: flex;
                    padding: 10px 0;
                    border: 1px solid #EEEEEE;
                    .start_option_content_item111 {
                        flex: 3;
                        padding-left: 30px;
                        line-height: 33px;
                        .start_option_content_text_click {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: #000000;
                        }
                    }
                    .start_option_content_item222 {
                        flex: 3;
                        padding-left: 30px;
                    }
                    .ewewewew {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 100px;
                        height: 33px;
                        line-height: 33px;
                        >span {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: #000000;
                        }
                    }
                }
                .start_option_content:not(:last-child) {
                    border-bottom: none;
                }
            }
        }
    }
    .start_preview11 {
        .ant-rate {
            font-size: 27px;
        }
    }
    .SingleChoice_add_item {
        width: 100%;
        display: flex;
        height: 26px; // align-items: flex-end;
        padding-top: 7px;
        >span {
            display: flex;
            align-items: center;
        }
        .add_question_item {
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
            margin: 0 20px; // color: #3E78ED;
        }
    }
}

.ant-popover-inner-content {
    .SingleChoice {
        pointer-events: none;
    }
    .SingleChoice_add_item {
        display: none;
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;