.c_main_wrap {
    width: 100%;
    height: 100vh;
    min-height: 900px;
    padding-top: 60px;
    overflow: hidden;
    background: #FFFFFF;
    .main_content_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .cont_t {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            .item {
                min-height: 430px;
                width: 330px;
                margin: 0 40px;
                background: #FFFFFF;
                box-shadow: 2.70588px 2.70588px 20.2941px rgba(0, 0, 0, 0.1);
                border-radius: 6.76471px;
                transition: all 0.2s;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: column;
                .title {
                    font-weight: 500;
                    margin-top: 15%;
                    font-size: 30px;
                }
                .desc {
                    margin-top: 4%;
                    font-size: 17px;
                    color: #888888;
                }
                .icon {
                    margin-top: 8%;
                }
                .btn_w {
                    margin-top: 11%;
                    margin-right: 3%;
                    >button{
                        width: 67px;
                        height: 32px;
                    }
                }
            }
            .item:hover {
                transform: scale(1.01);
                border: 3px solid #3E78ED
            }
        }
        .con_b {
            margin: 0 auto;
            width: 80%;
            min-height: 250px;
            padding-bottom: 30px;
            .wra_cc {
                display: flex;
                justify-content: space-around;
                align-items: center;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 20px 60px;
                .ll_c {
                    width: 300px;
                    display: flex;
                    >.text {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        padding: 20px;
                        >div:nth-child(1) {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            color: #333333;
                        }
                        >div:nth-child(2) {
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: #888888;
                        }
                    }
                }
                .rr_c {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    >button{
                        width: 67px;
                        height: 32px;
                    }
                }
            }
        }
    }
}

.cc_modal_wrap1 {
    .ant-input-suffix {
        position: absolute;
        right: -35px;
    }
}

.error_message {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 580px;
    height: 50px;
    line-height: 50px;
    background-color: red;
    text-align: center;
    background: rgba(227, 73, 73, 0.12);
    border-radius: 5px;
    span {
        font-family: PingFang SC;
        font-size: 14px;
        color: #E34949;
    }
}

.Batstudent-content {
    width: 100%;
    display: block;
    position: relative;
    .verticalLine {
        position: absolute;
        left: 20px;
        top: 40px;
        height: 166px;
        border: 1px solid #EBEBEB;
    }
    .item_content {
        display: flex; //align-items: center;
        .num {
            width: 40px;
            height: 40px;
            background: #ffffff;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            i {
                font-style: normal;
                list-style: none;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                color: #888888;
            }
        }
        .text {
            padding-left: 20px;
            display: inline-block;
            flex: 1 1;
            margin-top: 6px;
            .loading {
                background: #F8F8F8;
                width: auto;
                height: 32px;
                line-height: 32px;
                border-radius: 3px;
                display: inline-block;
                margin-bottom: 11px;
            
                .rotate_loading  svg{
                    animation: run 1s linear infinite;
                }
                em {
                    width: 18px;
                    height: 18px;
                    margin-left: 10px;
                    margin-right: 4px;
                    position: relative;
                    top: 3px;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
                span {
                    font-size: 14px;
                    color: #888888;
                }
                i {
                    margin-left: 17px;
                    margin-right: 15px;
                    svg {
                        cursor: pointer;
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            h4 {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                margin-bottom: 10px;
                -webkit-user-select: none;
                a {
                    color: #3E78ED;
                    text-decoration: underline;
                }
            }
            p {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                color: #E34949;
            }
            span {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: #888888;
            }
            .text_limit {
                display: block;
                margin-top: -24px;
            }
            .ant-upload {
                margin-bottom: 10px;
                .ant-btn {
                    vertical-align: middle;
                    img {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.Success-content {
    width: 100%;
    display: block;
    font-family: Noto Sans SC;
    font-style: normal;
    .img {
        margin: 0 auto;
        margin-top: 50px;
        width: 160px;
        height: 113px;
        margin-bottom: 31px;
    }
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #333333;
        margin-bottom: 14px;
    }
    h4 {
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #333333;
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #333333; // margin-bottom: 40px;
        a {
            color: #3E78ED;
            text-decoration: underline;
        }
    }
}

@keyframes run {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;