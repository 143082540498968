body {
    background: #F6F7FB;
}

// .ant-table-pagination.ant-pagination{
//     position: relative;
//     top:-75px;
// }
.HeaderText {
    height: 60px;
    padding: 0 30px;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    >.HeaderTextleft {
        position: relative;
        left: 2%;
        width: 65px;
        cursor: pointer;
        display: flex;
        align-items: center;
        >span {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
        }
    }
    >.HeaderTextright {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }
    >.HeaderTextBtn {
        position: relative;
        right: 6%;
        Button {
            border-radius: 3px;
        }
    }
}

.therules_wai {
    width: 100%;
    padding-bottom: 20px;
    background: #fff;
    margin: 0 auto;
}

.therules {
    width: 100%;
    min-width: 1200px;
    height: 800px;
    margin: 0 auto;
    margin-top: 80px;
    background: #FFFFFF;
    padding-top: 20px;
    .therules_title {
        padding: 0px 80px;
        padding-bottom: 20px;
        background: #fff;
        >span {
            padding-left: 10px;
            font-size: 22px;
            font-weight: 550;
        }
        >p {
            padding-left: 17px;
            padding-top: 10px;
            color: #333333;
        }
        .therules_type {
            width: 100%;
            border: 1px solid #F3F3F3;
            .therules_box {
                height: 614px;
                align-items: center;
                display: flex;
                justify-content: center;
                position: relative;
                .therules_p {
                    position: absolute;
                    bottom: 32%;
                    left: 36%;
                }
                .bounced_div {
                    width: 660px;
                    // margin-bottom: 30px !important;
                }
            }
            .roleRule {
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
            }
        }
    }
    .ant-table-cell {
        text-align: center;
    }
    .ant-table-cell {
        height: 40px;
        padding: 0 0;
    }
    .ant-modal-content {
        border-radius: 50px;
    }
    .ant-table {
        min-height: calc(100vh - 344px);
    }
    
}

.bounced {
    font-size: 18px;
    color: #333333;
    position: relative;
    .ant-modal-close-x {
        display: none;
    }
    .ant-form-item {
        margin-bottom: 18px;
    }
    #bounced_p {
        position: absolute;
        left: 0;
        bottom: 47px;
        width: 100%;
        height: 30px;
        background: #E7530114;
        line-height: 30px;
        padding-left: 25px;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #E75301;
    }
    .time_ipt {
        padding-left: 70px;
        margin-bottom: 20px;
        position: relative;
        >input {
            margin-right: 18px;
        }
    }
    .ant-modal-footer{
        button{
            width: 91px;
            height: 39px;
            margin-right: 22px;
        }
    }
    
}

.answertherules {
    width: 83%;
    min-width: 1200px;
    margin: 0 auto;
    .ant-pagination {
        padding-right: 20px;
    }
}

#border:hover {
    border: 1px solid #3E78ED !important;
    border-radius: 5px;
    color: #3E78ED;
    >i {
        svg path {
            fill: #3E78ED;
        }
    }
}

@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;