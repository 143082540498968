.headerAction {
    height: 60px;
    padding: 0 30px;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    >.headerActionleft {
        width: 65px;
        cursor: pointer;
        display: flex;
        align-items: center;

        >span {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
        }
    }

    >.headerActionright {
        flex: 1;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        justify-content: space-around;

        >.uul {
            width: 60%;
            display: flex;
            justify-content: center;
            padding-left: 27%;

            >.item {

                padding: 20px 30px;
                cursor: pointer;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;




                color: #2E2C41;

                opacity: 0.99;
                min-width: 113px;
            }

            .active {
                position: relative;
                color: #3E78ED;

                opacity: 0.99;
            }

            .active::before {
                position: absolute;
                content: '';
                width: 30px;
                height: 4px;

                background: #407AED;
                border-radius: 10px;
                bottom: 7px;

                transform: translate(64%);
            }
        }

        >.rrl {
            width: 20%;
            display: flex;
            justify-content: center;

            >.item {
                padding: 20px;
                cursor: pointer;
                min-width: 100px;
                white-space: nowrap;
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                color: #3E78ED;
                display: flex;
                align-items: center;
                >.save{
                    width: 70px;
                    height: 32px;
                }
                >.pre{
                    border-color: #3E78ED;
                    color:#3E78ED;                    
                    width: 64px;
                    height: 32px;
                }
                >.saveNot{
                    border-color: #3E78ED;
                    color:#3E78ED;
                }
            }

            >.item:nth-child(1)>span {
                display: flex;
                margin-right: 3px;
            }
        }
    }


}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;