.m_main_geren {
    width: 100%;
    height: 100vh;
    padding: 60px 13% 0 13%;
    background: #F6F7FB;
    margin-top: 23px;
    .m_content_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .m_content_top {
            width: 100%;
            display: flex;
            height: 186px;
            position: relative;
            background: #FFFFFF;
            >.questionbgc {
                right: 0%;
                position: absolute;
            }
            .m_content_jc {
                display: flex;
                padding: 10px 30px;
                padding-left: 0;
                span:nth-child(1) {
                    width: 3px;
                    height: 18px;
                    border-radius: 10px;
                    background: linear-gradient(148.89deg, #6EA8FF 2.69%, #485AFE 113.17%);
                    margin-top: 1px;
                }
                span:nth-child(2) {
                    font-size: 15px;
                    color: #000;
                    font-weight: 700;
                    margin-left: 11px;
                }
            } //  .m_content_an{
            //      margin: 13px 0px 0px 40px;
            //      >div:nth-child(1){
            //          margin-top: 13px;
            //      }
            //      >div:nth-child(2){
            //         margin-top: 13px;
            //         text-align: justify;
            //         span:nth-child(2){
            //             margin-left: 174px;
            //             margin-right: 174px;
            //         }
            //     }
            //     >div:nth-child(3){
            //         margin-top: 13px;
            //         span:nth-child(2){
            //             margin-left: 174px;
            //             margin-right: 174px;
            //         }
            //     }
            //  }
            .m_content_an {
                display: flex;
                >div {
                    flex: 1;
                    line-height: 31px;
                }
            }
        }
    }
    .m_content_bottom {
        background: #fff;
        margin-top: 20px;
        >div:nth-child(1) {
            display: flex;
            margin: 30px 0px 0px 44px;
            >span:nth-child(1) {
                width: 3px;
                height: 18px;
                border-radius: 10px;
                background: linear-gradient(148.89deg, #6EA8FF 2.69%, #485AFE 113.17%);
                margin-top: 1px;
            }
            >span:nth-child(2) {
                font-size: 15px;
                color: #000;
                font-weight: 700;
                margin-left: 11px;
            }
        }
        >div:nth-child(2) {
            >h3 {
                text-align: center;
                margin-top: 30px;
                color: #2A283D;
                font-size: 20px;
                font-weight: 700;
            }
            >.questionContent {
                padding: 2% 10% 0px 10%;
                textarea {
                    width: 620px;
                    height: auto;
                }
                >div{
                    margin-bottom: 30px;
                }
                .imgShow{
                    margin-top: 10px;
                    margin-left: 30px;
                    img{
                        margin-right: 10px;
                        border-radius: 2px;
                    }
                }
                >.oneSelect{
                    >div:nth-child(1){
                        margin-bottom: 20px;
                    }
                    >div:nth-child(2){
                        margin-left: 30px;
                        >div{
                            margin-bottom: 13px;
                        }
                    }
                }
                >.answerQuestion{
                    >div:nth-child(1){
                        margin-bottom: 20px;
                    }
                    >div:nth-child(2){
                        margin-left: 30px;
                    }
                }
                >.starQuestion{
                    >div:nth-child(1){
                        margin-bottom: 20px;
                    }
                    >div:nth-child(2){
                        margin-left: 30px;
                    }
                }
            }
            >.changeQuestion {
                height: 50px;
                width: 100%;
                line-height: 50px;
                margin-top: 50px;
                text-align: center;
                border-top: 1px solid #EDEDED;
                border-bottom: 1px solid #EDEDED;
                >span {
                    color: #333333;
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    cursor: pointer;
                }
                >span:nth-child(2) {
                    padding: 0 34px;
                    color: #E2E2E2;
                }
                >span:nth-child(1):hover {
                    color: #3E78ED;
                }
                >span:nth-child(3):hover {
                    color: #3E78ED;
                }
            } // >div:nth-child(2) {
            //     padding: 2% 10% 0px 10%;
            //     >div:nth-child(3) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;
            //         }
            //         >div:nth-child(3) {
            //             margin-top: 14px; // margin-left: 35px;/
            //         }
            //     }
            //     >div:nth-child(4) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;
            //         }
            //         >div:nth-child(3) {
            //             margin-top: 14px; // margin-left: 35px;/
            //         }
            //         >div:nth-child(4) {
            //             margin-top: 14px; // margin-left: 35px;///
            //         }
            //     }
            //     >div:nth-child(5) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;
            //         }
            //         >div:nth-child(3) {
            //             margin-top: 14px;
            //             margin-left: 35px;
            //         }
            //     }
            //     >div:nth-child(6) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;
            //         }
            //     }
            //     >div:nth-child(7) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;///
            //         }
            //     }
            //     >div:nth-child(8) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         >div:nth-child(1) {
            //             margin-top: 20px;
            //         }
            //         >div:nth-child(2) {
            //             margin-top: 20px; // margin-left: 35px;
            //         }
            //     }
            //     >div:nth-child(9) {
            //         margin-top: 40px;
            //         margin-bottom: 30px;
            //         height: 50px; // display: flex;
            //         text-align: center;
            //         border-bottom: 1px solid #EDEDED;
            //         border-top: 1px solid #EDEDED;
            //         text-align: center;
            //         line-height: 3px;
            //         >span:nth-child(1) {
            //             margin-top: 20px;
            //             line-height: 50px;
            //         }
            //         >span:nth-child(2) {
            //             margin-right: 34px;
            //             margin-left: 34px;
            //             color: #E2E2E2;
            //         }
            //         >span:nth-child(3) {
            //             margin-top: 20px;
            //             line-height: 50px;
            //         }
            //     }
            // }
            // >div:nth-child(3) {
            //     border-bottom: 1px solid #EDEDED;
            //     border-top: 1px solid #EDEDED;
            //     height: 50px;
            //     padding: 2% 10% 0px 10%;
            //     margin-top: 30px;
            //     text-align: center;
            //     line-height: 6px;
            //     cursor: pointer;
            //     >div:nth-of-type(1) {
            //         height: 40px;
            //         align-items: center;
            //     }
            //     >span:nth-child(1) {
            //         margin-right: 20px;
            //     }
            //     >span:nth-child(2) {
            //         color: #EDEDED;
            //     }
            //     >span:nth-child(3) {
            //         margin-left: 20px;
            //     }
            // }
        }
        .invaild_footer {
            display: flex;
            justify-content: flex-start;
            height: 60px;
            background: #FFFFFF;
            box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
            align-items: center;
            padding-left: 53px;
            margin-top: 34px; // position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 104;
            .footer_button {
                padding: 20px;
                margin-left: 60px;
                /* margin: 30px; */
                line-height: 62px;
                display: flex;
                >div:nth-child(2) {
                    margin-left: 30px;
                    button{
                        border-color: #3E78ED;
                        color: #3E78ED;
                    }
                }
            }
        }
        .blue {
            color: #3E78ED;
            width: 52px;
            height: 24px;
            text-align: center;
            line-height: 23px;
            cursor: pointer;
        }
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;