.ModuleSet {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #F6F7FB;
    .headerAction {
        height: 60px;
        padding: 0 30px;
        background: #fff;
        box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        >.headerActionleft {
            width: 65px;
            cursor: pointer;
            display: flex;
            align-items: center;
            >span {
                font-weight: bold;
                font-size: 16px;
                color: #333333;
            }
        }
        >.headerActionright {
            flex: 1;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            justify-content: space-around;
            >.uul {
                width: 60%;
                display: flex;
                justify-content: center;
                padding-left: 27%;
                >.item {
                    padding: 20px 30px;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    color: #2E2C41;
                    opacity: 0.99;
                    min-width: 113px;
                }
                .active {
                    position: relative;
                    color: #3E78ED;
                    opacity: 0.99;
                }
                .active::before {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 4px;
                    background: #407AED;
                    border-radius: 10px;
                    bottom: 7px;
                    transform: translate(64%);
                }
            }
            >.rrl {
                width: 20%;
                padding-left: 16%;
                display: flex;
                justify-content: center;
                >.item {
                    padding: 20px;
                    cursor: pointer;
                    min-width: 100px;
                    white-space: nowrap;
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #3E78ED;
                    display: flex;
                    align-items: center;
                    Button {
                        width: 70px;
                        height: 32px;
                        border-radius: 4px;
                    }
                }
                >.item:nth-child(1)>span {
                    display: flex;
                    margin-right: 3px;
                }
            }
        }
    }
    .m_left {
        width: 256px;
        background: #fff;
        height: 100%;
        padding: 22px;
        .module_set {
            width: 160px;
            margin: 0 auto;
            .h {
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                margin-bottom: 20px;
            }
            .theme_list {
                display: flex;
                flex-direction: column;
                .temp {
                    width: 160px;
                    height: 100%;
                    margin-bottom: 30px;
                    cursor: pointer;
                    .img {
                        width: 100%;
                        height: 88px;
                        margin-bottom: 12px;
                        background: #F7F9FB;
                        border: 1px dashed #D9EFF5;
                        border-radius: 3px;
                        overflow: hidden;
                        img {
                            width: 160px;
                            height: 88px;
                        }
                    }
                    .t_name {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        text-align: center;
                    }
                }
                >.active {
                    .img {
                        border: 2px solid #3E78ED;
                    }
                    .t_name {
                        color: #3E78ED;
                    }
                }
            }
        }
    }
    .m_center {
        flex: 1;
        height: 100%;
        background: #F6F7FB;
        .switch_btn {
            width: 160px;
            height: 36px;
            margin: 35px auto;
            background: #FFFFFF;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon_c {
                cursor: pointer;
                svg {
                    margin-right: 4px;
                    position: relative;
                    top: 3px;
                }
                span {
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    color: #888888;
                }
            }
            >.active {
                svg path {
                    fill: #3E78ED;
                }
                span {
                    color: #3E78ED;
                }
            }
            .line {
                width: 1px;
                height: 13px;
                background: #E2E2E2;
                border-radius: 10px;
                margin: auto 16px;
            }
        }
        .module_show {
            width: 100%; // height: 100%;
            // height: 30px;
            .phone {
                width: 315px;
                height: 593px;
                margin: 0 auto;
                cursor: pointer;
                overflow: hidden;
                .ph_bg {
                    width: 253px;
                    height: 542px;
                    margin: 21px 34px 30px 28px;
                    overflow: hidden;
                    >.scroll {
                        width: 100%;
                        height: 477px;
                        margin-top: 44px;
                        overflow: auto;
                        background-color: #fff;
                        .ph_banner {
                            width: 100%;
                            height: 95px;
                            img {
                                display: block;
                                margin: 0 10px;
                                width: 233px;
                                height: 95px;
                                border-radius: 3px;
                            }
                        }
                        .ph_content {
                            width: 100%;
                            height: 562px;
                            border-radius: 3px;
                            overflow: hidden;
                            img {
                                width: 253px;
                                height: 562px;
                            }
                        }
                    }
                    >.scroll::-webkit-scrollbar {
                        width: 0px;
                        height: 0px;
                    }
                }
            }
            .pc {
                width: 700px;
                height: 800px;
                margin: 0 auto;
                background-color: #fff;
                overflow: hidden;
                >.pc_scroll {
                    width: 660px;
                    height: 760px;
                    margin: 20px auto;
                    // overflow: auto;
                    .pc_banner {
                        width: 100%;
                        height: 180px;
                        margin: 0 auto;
                        img {
                            display: block;
                            margin: 0 20px;
                            width: 610px;
                            height: 180px;
                            border-radius: 3px;
                        }
                    }
                    .pc_content {
                        width: 100%; // height: 696.8px;
                        position: relative;
                        >.title {
                            width: 100%;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 27px;
                            text-align: center;
                            color: #2A283D;
                            opacity: 0.82;
                            position: absolute;
                            top: 20px;
                        }
                        img {
                            width: 660px;
                            height: 696.8px;
                        }
                    }
                }
                >.pc_scroll::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    .m_right {
        width: 240px;
        background: #fff;
        height: 100%;
        padding: 22px;
        .pf_select {
            width: 160px;
            margin: 0 auto;
            .h {
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                margin-bottom: 14px;
            }
            .up_img {
                width: 160px;
                height: 88px;
                margin-bottom: 30px;
                cursor: pointer;
                background: #F7F9FB;
                border-radius: 3px;
                text-align: center;
                overflow: hidden;
                .uploader>.ant-upload {
                    width: 158px;
                    height: 86px;
                }
                .img_show {
                    width: 100%;
                    height: 100%;
                    img{
                        width: 160px;
                        height: 88px;
                    }
                    svg {
                        margin: 19px 65px 3px;
                    }
                    span {
                        font-family: Noto Sans SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 17px;
                        display: block;
                        height: 17px;
                        color: #3E78ED;
                    }
                }
            }
            .bgc_c {
                width: 160px;
                margin: 0 auto;
                .h_t {
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    color: #333333;
                    margin-bottom: 30px;
                }
                .btn_list {
                    display: flex;
                    flex-direction: column;
                    .btn_c {
                        width: 100%;
                        height: 30px;
                        margin-bottom: 30px;
                        cursor: pointer;
                        span {
                            line-height: 25px;
                            padding-right: 20px;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: #000000;
                        }
                        em {
                            display: inline-block;
                            transition: all 0.2s linear;
                            border-radius: 15px;
                            cursor: pointer;
                        }
                        .off {
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            position: relative;
                            top: 8px;
                        }
                        .on {
                            width: 50px;
                            height: 30px;
                            border-radius: 15px;
                            line-height: 30px;
                            position: relative;
                            top: 8px; // background-color: #3E78ED;
                            svg {
                                position: absolute;
                                right: 2px;
                                top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.crop_modal {
    width: 660px !important;

    .ant-modal-body {
        padding: 0;
    }

    .preview {
        width: px;

        .small {
            width: 190px;
            height: 90px;
        }

    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;