.my_img_crop {
    // width: 500px;
    .ReactCrop__image {
        max-width: 25000px;
        width: 333px;
        height: 333px;
    }
}

.crop_box {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #F3F3F3;
    .crop_img {
        // flex: 1;
        padding: 26px 22px;
        padding-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top {
            margin-bottom: 24px;
        }
    }
    .preview {
        padding: 26px 22px;
        p {
            width: 100%;
            text-align: left;
            color: #242424;
            font-size: 15px;
            font-weight: 600;
        }
        .image_container {
            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 42px;
            }
            img {
                border-radius: 2px;
                margin-bottom: 5px;
            }
            span {
                color: #333333;
                font-size: 12px;
            }
        }
    }
}

.bottom {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    text-align: right;
    .sure {
        display: block;
        width: 91px;
        height: 39px;
        margin-right: 30px;
        background: #3E78ED;
        border-radius: 5px;
        font-size: 16px;
        color: #ffffff;
    }
    .cancel {
        width: 91px;
        height: 39px;
        margin-right: 30px;
        display: block;
        // border: 1px solid #3E78ED;
        border-radius: 5px;
        // color: #3E78ED;
        color: #888888;
        font-size: 16px;
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;