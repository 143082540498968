.previewQuestion {
    display: flex;
    flex-direction: column;
    background-color: #F6F7FB;
    padding: 60px 10% 0 10%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .topError {
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        left: 0%;
        font-size: 14px;
        color: #E75301;
        background: #FFFBE6;
        z-index: 2;
    }
    .questionHeader {
        background-color: #fff;
        padding: 0 15%;
        margin: 4% 0 1% 0;
        border-radius: 5px;
        .bgi {
            width: 100%;
            height: 160px;
            background-color: red;
            margin: 30px 0 20px;
        }
        .title {
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #2A283D;
            margin-bottom: 3%;
        }
        .introduction {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: #333333;
            margin-bottom: 3%;
        }
    }
    .questionBody {
        display: flex;
        padding-bottom: 1%;
        .body_left {
            width: 60%;
            border-radius: 5px;
            background-color: #fff;
            .questionParagraph {
                font-weight: 600;
                font-size: 15px;
                margin: 30px 0;
            }
            .optionText {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 200px;
            }
            .questionTitle {
                margin-bottom: 15px;
                font-size: 16px;
                font-weight: 500;
                .questionTitleImg {
                    width: 83%;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 1%;
                    margin-left: 5%;
                    .imgIcon {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 3%;
                        left: 83%;
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 13px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        i {
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 2px;
                    }
                }
            }
            .q_body {
                margin-left: 30px;
                .optionImg {
                    display: flex;
                    flex-direction: column;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
                .ant-rate-text {
                    color: #FA9929;
                    margin: 0 0 17px 20px;
                }
            }
            #rateDiv {
                display: flex;
                align-items: center;
            }
            .ant-radio-wrapper {
                display: flex;
                margin-bottom: 10px;
                >span:nth-child(2) {
                    margin-left: 20px;
                }
                .ant-radio {
                    position: absolute;
                }
            }
            .ant-checkbox-wrapper {
                display: flex;
                margin-bottom: 10px;
                >span:nth-child(2) {
                    margin-left: 20px;
                }
                .ant-checkbox {
                    position: absolute;
                }
            }
        }
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;