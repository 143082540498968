.s_main_body {
    width: 100vw;
    min-height: 100vh;
    padding: 60px 5% 0 5%; // overflow: hidden;
    background: #F6F7FB;
    .s_content {
        width: 100%;
        min-width: 1200px;
        height: calc(100vh - 100px);
        margin: 20px auto;
        background: #FFFFFF;
        border-radius: 5px;
        overflow: hidden;
        .s_img {
            width: 253px;
            text-align: center;
            height: 176.6px;
            margin: 0 auto;
            margin-top: 190px;
            background-size: 100%;
        }
        .s_sic {
            height: 30px;
            text-align: center;
            line-height: 30px;
            overflow: hidden;
            svg {
                position: relative;
                top: 3px;
            }
            span {
                padding-left: 10px;
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                color: #333333;
            }
        }
        p {
            margin-top: 20px;
            text-align: center;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #333333;
        }
        .s_btn {
            width: 140px;
            height: 40px;
            background: #2E69E0;
            border: 1px solid #3E78ED;
            box-sizing: border-box;
            border-radius: 5px;
            color: #FFFFFF;
            cursor: pointer;
            line-height: 40px;
            margin: 0 auto;
            margin-top: 25px;
            text-align: center;
            svg{
                position: relative;
                top: 3px;
            }
            span {
                padding-left: 9.5px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #FFFFFF;
            }
        }
    }
    .HeaderTextright{
        margin-right: 58px;
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;