.question {
    display: flex;
    flex-direction: column;
    background-color: #F6F7FB;
    padding: 60px 10% 0 10%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .questionHeader {
        background-color: #fff;
        padding: 0 6%;
        margin: 1% 0;
        border-radius: 5px;
        .title {
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #2A283D;
            margin: 3% 0;
        }
        .introduction {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: #333333;
            margin-bottom: 3%;
        }
    }
    .questionBody {
        // display: flex;
        padding-bottom: 1%;
        .body_left {
            width: 74%;
            margin-right: 1%;
            padding: 2% 25% 2% 6%;
            border-radius: 5px;
            min-height: 810px;
            height: auto;
            background-color: #fff;
            float: left;
            .questionParagraph {
                font-weight: 600;
                font-size: 15px;
                margin: 30px 0;
            }
            .optionText {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 200px;
            }
            .questionTitle {
                margin-bottom: 15px;
                font-size: 16px;
                font-weight: 500;
                .questionTitleImg {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 1%;
                    margin-left: 5%;
                    .imgIcon {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 3%;
                        left: 83%;
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 13px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        i {
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 2px;
                    }
                }
            }
            .q_body {
                margin-left: 30px; // .maxLengthInput {
                //   .ant-input {
                //     color: red
                //   }
                //   .lengthNum{
                //     color: red;
                //   }
                // }
                .optionImg {
                    display: flex;
                    flex-direction: column;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
                .ant-rate-text {
                    color: #FA9929;
                    margin: 0 0 17px 20px;
                }
            }
            #rateDiv {
                display: flex;
                align-items: center;
            }
            .ant-radio-wrapper {
                display: flex;
                margin-bottom: 10px;
                >span:nth-child(2) {
                    margin-left: 20px;
                }
                .ant-radio {
                    position: absolute;
                }
            }
            .ant-checkbox-wrapper {
                display: flex;
                margin-bottom: 10px;
                >span:nth-child(2) {
                    margin-left: 20px;
                }
                .ant-checkbox {
                    position: absolute;
                }
            }
        }
        .body_right {
            width: 25%;
            float: right;
            >div {
                border-radius: 5px;
                background-color: #fff;
            }
            .count {
                height: 400px;
                margin-bottom: 3%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                >div:nth-child(1) {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: 0.02em;
                    color: #333333;
                }
                >div:nth-child(3) {
                    margin-bottom: 30px;
                }
            }
            .card {
                min-height: 400px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .cardTitle {
                    font-weight: 500;
                    font-size: 18px;
                    letter-spacing: 0.02em;
                    color: #333333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 10% 0 5% 0;
                }
                .cardBody {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    width: 300px;
                    .cardItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #EEEEEE;
                        color: #888;
                        width: 50px;
                        height: 50px;
                        border-radius: 25px;
                        margin: 4%;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .pass {
                        border: 1px solid #3E78ED;
                        color: #3E78ED;
                    }
                    .activeIndex {
                        background-color: #3E78ED;
                        color: white;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .question .questionBody .body_right .card {
        .cardTitle {
            font-size: 17px
        }
        .cardBody {
            width: 250px;
            .cardItem {
                width: 40px;
                height: 40px;
            }
        }
    }
}
#questionForm{
    .ant-form-item-explain{
        margin-left: 32px;
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;