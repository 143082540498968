.EditQuestion{
    width: 100%;
    height: 100%;
    user-select: none;
    .EditQuestion_content{
        margin-top: 60px;
        height: 100%;
    }
}

@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;