.m_main_invaild {
    width: 100%;
    min-height: 100vh;
    min-width: 1200px;
    padding: 60px 10% 0 10%;
    overflow: hidden;
    background: #F6F7FB;
    margin-top: 20px;
    .ant-table {
        min-height: calc(100vh - 500px);
    }
    .m_create_invay {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column; // background:#FFF;
        .m_create_top {
            height: 114px;
            background: #fff;
            display: flex;
            justify-content: space-between;
            >div:nth-child(1) {
                display: flex;
                padding-left: 3%;
                >div:nth-child(2) {
                    h3 {
                        margin-top: 30px;
                    }
                    div {
                        color: #888;
                        font-size: 14px;
                    }
                }
            }
            >div:nth-child(2) {
                margin-right: 30px;
                >div:nth-child(1) {
                    margin-top: 30px;
                    span {
                        font-size: 25px;
                        color: #333;
                    }
                }
                >div:nth-child(2) {
                    color: #333;
                    font-size: 12px;
                }
            }
        }
        .m_create_buttom {
            margin-top: 20px;
            background-color: #fff;
            >div:nth-child(1) {
                display: flex;
                height: 72px;
                border: 1px solid #EEEEEE;
                >div:nth-child(1) {
                    display: flex;
                    margin: 20px 60px 20px 20px;
                    >span:nth-child(1) {
                        line-height: 30px; // margin-right: 20px;
                    }
                    >span:nth-child(2) {
                        margin-left: 20px;
                    }
                }
                >div:nth-child(2) {
                    display: flex;
                    margin-top: 20px;
                    line-height: 30px; // margin-right: 20px;
                    >span:nth-child(1) {
                        line-height: 30px; // margin-right: 20px;
                    }
                    >span:nth-child(2) {
                        margin-left: 20px;
                    }
                }
            }
            >div:nth-child(2) {
                padding: 10px; // padding-bottom: 100px;
                // background: #fff;
            }
            .ant-checkbox-wrapper {
                margin-left: 36px;
            }
            .vertical_j{
                justify-content: center;
            }
        }
        .invaild_footer {
            display: flex;
            justify-content: flex-start;
            height: 80px;
            background: #FFFFFF;
            box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
            align-items: center;
            padding-left: 53px; // position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 104;
            .footer_button {
                padding: 20px;
                margin-left: 115px;
                /* margin: 30px; */
                line-height: 62px;
                display: flex;
                >div:nth-child(2) {
                    margin-left: 30px;
                }
            }
        }
        .action {}
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;