body {
    background: #F6F7FB;
}

.advancedSettings {
    width: 100%;
    // background:#F6F7FB;

    .headerAction {
        height: 60px;
        padding: 0 30px;
        background: #fff;
        box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        >.headerActionleft {
            width: 65px;
            cursor: pointer;
            display: flex;
            align-items: center;

            >span {
                font-weight: bold;
                font-size: 16px;
                color: #333333;
            }
        }

        >.headerActionright {
            flex: 1;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            display: flex;
            justify-content: space-around;

            >.uul {
                width: 60%;
                display: flex;
                justify-content: center;
                padding-left: 27%;

                >.item {

                    padding: 20px 30px;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;




                    color: #2E2C41;

                    opacity: 0.99;
                    min-width: 113px;
                }

                .active {
                    position: relative;
                    color: #3E78ED;

                    opacity: 0.99;
                }

                .active::before {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 4px;
                    background: #407AED;
                    border-radius: 10px;
                    bottom: 7px;

                    transform: translate(64%);
                }
            }

            >.rrl {
                width: 20%;
                display: flex;
                justify-content: center;

                >.item {
                    padding: 20px;
                    cursor: pointer;
                    min-width: 100px;
                    white-space: nowrap;
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #3E78ED;
                    display: flex;
                    align-items: center;
                }

                .item_sp {
                    background: #3E78ED;
                    border: none;
                    color: #FFFFFF;
                    display: inline-block;
                    width: 70px;
                    height: 32px;
                    padding-left: 22px;
                    border-radius: 5px; 
                    line-height: 32px;
                }

                >.item:nth-child(1)>span {
                    display: flex;
                    margin-right: 3px;
                }
            }
        }


    }

    .senior_main {
        margin: auto;
        width: 1200px;
        background: #FFFFFF;
        margin-top: 85px;
        margin-bottom: 30px;
        .theanswerlimit {
            width: 820px;
            background: #FFFFFF;
            // height: 648px;
            margin: auto;
            // border: 1px solid darkcyan;

            .limit {
                width: 820px;
                margin: auto;
                font-size: 22px;
                color: #333333;
                padding-top: 30px;
                font-weight: 550;

            }

            .answer_num {
                color: #333333;
                margin: 20px 20px;

                >h3 {
                    font-size: 17px;
                    font-weight: 550;
                }
            }

            .answer_border {
                height: 45px;
                border-bottom: 1px solid #EEEEEE;
                clear: both;

                .iphone {
                    >p {
                        float: left;
                    }

                    float: left;
                }
            }

            .answer_ip {
                margin-top: 20px;
                height: 48px;
                clear: both;
                margin-bottom: 10px;

                .ip_limit {
                    float: left;

                    >p {
                        margin-bottom: 0;
                    }
                }
            }

            .answer_acar {
                text-align: left;
                border-top: 1px solid #EEEEEE;
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #EEEEEE;
                background: #F6F7FB;
                width: 820px;
                padding-left: 30px;

                .acar {
                    padding: 0 10px;
                }

                .answer_car {
                    width: 120px;
                }
            }

            .answerlimit {
                margin: 20px 20px;
                padding-bottom: 20px;

                >h3 {
                    font-size: 17px;
                    font-weight: 550;
                    margin-bottom: 20px;
                }

                .answerlimit_time {
                    position: relative;
                    // height: 90px;
                    clear: both;

                    .answerlimit_time1 {
                        width: 100%;
                        
                    }

                    >div {
                        >p {
                            margin-left: 18px;

                           
                        }
                    }
                    .answerlimit_open {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    .answerlimit_open_right {
                        border-bottom: 1px solid #EEEEEE;
                       
                        background: #F6F7FB;
                        width: 820px;
                        border-top: 1px solid #EEEEEE;
                        padding-top: 25px;
                        padding-left: 30px;
                    }
                   
                }
                
            }
        }
    }

    .weChatlimit {
        // height: 401px;

        margin: auto;
        width: 1200px;
        background: #FFFFFF;

        .limit_main {
            width: 820px;
            margin: auto;

            .limit_box {
                padding-top: 20px;
                width: 100%;
                font-size: 22px;

                >span {
                    margin-left: 10px;
                }
            }

            .limit_ipt {
                width: 100%;
                height: 160px;
                border-bottom: 1px solid #EEEEEE;
                background: #F6F7FB;
                border-top: 1px solid #EEEEEE;
                padding: 13px 18px;
            }

            .anonymous {
                width: 100%;
                height: 80px;
                margin-top: 20px;
                border-bottom: 1px solid #EEEEEE;

            }
        }

    }

    .remindset {
        height: 414px;
        margin: auto;
        width: 1200px;
        background: #FFFFFF;

        .aaa {
            background: #FFFFFF;
            width: 820px;
            margin: auto;
            margin-top: 20px;
            height: 414px;

        }
    }

    .weChatset {
        height: 829px;
        border: 1px solid darkcyan;
        margin-top: 20px;
        margin: auto;
        width: 1200px;
    }

    .thequalitycontrol {
        margin: auto;
        width: 1200px;
        background: #FFFFFF;
        margin-top: 20px;

        .thequalitycontrol_box {
            width: 820px;
            margin: auto;
            padding-bottom: 30px;
            .thequalitycontrol_font {

                width: 100%;
                padding-top: 20px;
                width: 100%;
                font-size: 22px;

                >span {
                    margin-left: 10px;
                }
            }

            .quality {
                margin: 15px;
                font-size: 17px;
                font-weight: 550;
            }

            .quality_ceiling {
                width: 820px;

                >p {
                    position: relative;

                    >span {
                        margin-left: 32px;
                    }

                    .quality_pro {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }

            .quality_p {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid #EEEEEE;
                background: #F6F7FB;
                padding-top: 15px;
                padding-left: 32px;
            }

            .screening {
                width: 100%;
                height: 60px;
                padding-top: 18px;
                border-bottom: 1px solid #EEEEEE;
                clear: both;
                padding-bottom: 20px;
                border-bottom: 1px solid #EEEEEE;
                >span {
                    float: left;
                    padding-left: 32px;

                }

                >i {
                    float: right;
                    padding-right: 14px;
                }
            }
        }
    }

    .otherSettings {
        margin: auto;
        width: 1200px;
        height: 197px;
        border: 1px solid darkcyan;
        margin-top: 20px;
    }

}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;