.mudule_preview {
    display: flex;
    flex-direction: column;
    background-color: #F6F7FB;
    padding: 60px 10% 0 10%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .headerText {
        height: 60px;
        padding: 0 30px;
        background: #fff;
        box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        .quit_pre {
            position: absolute;
            cursor: pointer;
            left: 28px;
            height: 23px;
            display: flex;
            span {
                padding-left: 9px;
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #333333;
                align-items: center;
            }
        }
        .h_total {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #888888;
        }
    }
    .m_center {
        flex: 1;
        background: #F6F7FB;
        .switch_btn {
            width: 238px;
            height: 60px;
            margin: 35px auto;
            background: #FFFFFF;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon_c {
                cursor: pointer;
                svg {
                    margin-right: 4px;
                    position: relative;
                    top: 3px;
                }
                span {
                    padding-left: 7px;
                    font-family: Noto Sans SC;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    color: #888888;
                }
            }
            >.active {
                svg path {
                    fill: #3E78ED;
                }
                span {
                    color: #3E78ED;
                }
            }
            .line {
                width: 1px;
                height: 13px;
                background: #E2E2E2;
                border-radius: 10px;
                margin: auto 30px;
            }
        }
        .module_show {
            width: 100%; // height: 100%;
            // height: 30px;
            .phone {
                width: 372px;
                height: 700px;
                margin: 0 auto;
                cursor: pointer;
                overflow: hidden;
                .ph_bg {
                    width: 296px;
                    height: 644px;
                    margin: 30px 42px 1px 34px;
                    overflow: hidden;
                    >.scroll {
                        width: 100%;
                        height: 554px;
                        margin-top: 56px;
                        overflow: auto;
                        background-color: #fff;
                        .ph_banner {
                            width: 100%;
                            height: 111px;
                            
                            img {
                                display: block;
                                margin: 0 10px;
                                width: 276px;
                                height: 111px;
                                border-radius: 3px;
                            }
                        }
                        .ph_content {
                            width: 100%;
                            height: 656px;
                            border-radius: 3px;
                            overflow: hidden;
                            img {
                                width: 296px;
                                height: 656px;
                            }
                        }
                    }
                    >.scroll::-webkit-scrollbar {
                        width: 0px;
                        height: 0px;
                    }
                }
            }
            .pc {
                width: 1200px; // height: 739px;
                margin: 0 auto;
                background-color: #fff;
                overflow: hidden;
                >.pc_scroll {
                    width: 820px;
                    height: calc(100% - 40px);
                    margin: 90px auto;
                    // overflow: auto;
                    .pc_banner {
                        width: 100%;
                        height: 160px;
                        margin: 0 auto;
                        margin-bottom: 10px;
                        background-color: #fff;
                        img {
                            display: block;
                            margin: 0 25px;
                            width: 770px;
                            height: 100%;
                            border-radius: 3px;
                        }
                    }
                    .pc_content {
                        width: 100%; // height: 696.8px;
                        height: calc(100% - 318%);
                        position: relative;
                        >.title {
                            width: 100%;
                            font-family: Noto Sans SC;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 27px;
                            text-align: center;
                            color: #2A283D;
                            opacity: 0.9;
                            position: absolute;
                            top: 20px;
                        }
                        img {
                            width: 100%; // height: 180px;
                            height: 100%;
                        }
                    }
                }
                >.pc_scroll::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;