.main_box {
    width: 100%;
    min-height: 100%;
    padding: 60px 13% 0 13%;
    background: #F6F7FB;
    .title_bread{
        width: 100%;
        height: 40px;
        line-height: 62px;
    }
    .main_create {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #FFF;
    }

    .m_buttom {
        height: 60px;
        border-bottom: 1px solid #EEEEEE;

        >div:nth-child(1) {
            display: flex;
            margin: 20px 0px 0px 0px;

            >div {
                font-weight: 700;
                color: #333;
                font-size: 14px;
                display: flex;
                >span {
                    margin-left: 20px;
                    border: 1px solid #E0E0E0;
                }
            }

            >span {
                margin-left: 40px;
                font-size: 14px;
                color: #333;
                font-weight: 500;
                cursor: pointer;
                width: 56px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
                position: relative;
            }
            .blue_btn{
                color: #3E78ED;
            }
            .blue_btn::before {
                color: #3E78ED;
                border-bottom: 2px solid #3E78ED;
                content: " ";
                position: absolute;
                width: 20px;
                height: 9px;
                top: 30px;
                left: 18px;
            } 
        }

        >div:nth-child(2) {
            display: flex;
            margin: 20px 0px 20px 30px;

            >div:nth-child(1) {
                width: 450px;

                >span:nth-child(2) {
                    margin-left: 20px;
                    cursor: pointer;
                }

            }

            >div:nth-child(2) {
                >span:nth-child(2) {
                    margin-left: 20px;
                    cursor: pointer;
                }

            }
        }
    }

    .ipt {
        height: 60px;
        line-height: 60px;
        position: relative;

        #c_input {
            position: absolute;
            top: 10px;
            right: 50px;
        }

        .c_span {
            margin-left: 20px;
            color: #888888;
        }

        >div {
            display: flex;
            justify-content: space-between;

            >div:nth-child(1) {
                >Button:nth-child(1) {
                    margin: 14px 20px 0px 30px;
                }

            }

            >div:nth-child(2) {
                margin: 15px;

                img {
                    position: relative;
                    left: 23px;
                    z-index: 10;
                    padding-left: 24px;
                }

                .in {
                    padding-left: 24px;
                }

            }
        }
    }

    .main_border {
        padding: 10px;
        flex: 1;

        .h_border {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            position: relative;
            border: 1px solid #F3F3F3;

            .h_item {

                width: 49%;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                min-height: 160px;
                margin-bottom: 20px;

                // >div:nth-child(1){
                //     　position:relative
                // }
                >div:nth-child(1) {
                    position: relative;
                    height: 40px;

                    //                     top:0
                    // 　　                left:0;
                    span {
                        position: absolute;
                        left: 4px;
                        top: 4px;
                        color: #fff;
                    }
                }

                >div:nth-child(2) {

                    margin: 0px 25px 20px 35px;

                    >div:nth-child(1) {
                        display: flex;
                        justify-content: space-between;

                        >div:nth-child(1) {
                            display: flex;

                            >div:nth-child(1) {
                                h3 {
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 23px;

                                    color: #333333;
                                }

                                span {}
                            }


                        }
                    }

                    >div:nth-child(2) {
                        margin-bottom: 14px;
                    }

                }

                .backgrounf_image {
                    border-radius: 100px;
                    height: 22px;
                    padding: 10px;
                    display: flex;
                    margin-left: 20px;
                    margin-top: 2px;

                    position: static;
                    align-items: center;
                }

                .background_lei {
                    font-size: 12px;
                    text-align: center;

                }

                .bottom_foo {
                    display: flex;
                    justify-content: space-between;
                    line-height: 40px;
                    height: 40px;
                    position: relative;

                    .c_fonts {
                        font-size: 14px;
                        line-height: 40px;

                        .template_foo {
                            cursor: pointer;
                            display: inline-block;
                            line-height: 40px;

                            em {
                                position: relative;
                                top: 3px;
                            }

                        }
                        .template_foo:hover {
                            svg path {
                                fill: #3E78ED;
                                stroke: #3E78ED;
                            }
                            color: #3E78ED;
                        }
                        .template_foot{
                            cursor: pointer;
                            display: inline-block;
                            line-height: 40px;
                            margin-left: 20px;
                            em {
                                position: relative;
                                top: 3px;
                            }
                        }
                        .template_foot:hover {
                            svg path {
                                fill: #3E78ED;
                            }
                            color: #3E78ED;
                        }
                    }

                    >div:nth-child(1) {
                        font-size: 14px;
                        color: #888;
                        margin-left: 35px;
                        line-height: 40px;

                        >span:nth-child(2) {
                            margin: 5px;
                        }

                        >span:nth-child(4) {
                            margin: 5px;
                        }

                        >span:nth-child(5) {
                            border: 1px solid dashed;
                        }
                    }

                    >div:nth-child(2) {
                        color: #888888;
                        margin-right: 20px;
                        line-height: 40px;

                        span {
                            margin-left: 3px;
                        }
                    }

                }

                .hua_footer {

                    justify-content: space-between;
                    height: 40px;
                    background: whitesmoke;
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    right: 0;
                    display: none;

                    >div:nth-of-type(1) {
                        height: 40px;
                        align-items: center;
                        margin-right: -4px;
                        margin-left: 26px;
                    }

                    div {
                        display: flex;
                        justify-content: space-between;

                        >div:nth-child(1) {
                            display: inline-block;

                            color: #7F8693;
                            font-size: 12px;

                        }

                        >div:nth-child(2) {

                            display: inline-block;

                            margin-right: 11px;
                            // margin-top: -13px;

                            div {
                                margin-right: 6px;
                                display: inline-block;

                                >span:nth-child(1) {
                                    img {
                                        margin-top: -3px;
                                    }


                                }

                                >span:nth-child(2) {
                                    color: #7F8693;
                                    font-size: 12px;
                                    margin-top: 4px;
                                    margin-left: 3px;
                                }
                            }

                        }
                    }

                }


            }

            .h_item:nth-child(odd) {
                margin-left: 5px;
            }
            .h_item:nth-child(even) {
                margin-right: 5px;
            }
            .hh_item {
                &:hover .hua_footer {
                    display: block;
                    cursor: pointer;

                }

                &:hover .pp {
                    display: none;
                    cursor: pointer;

                }
            }

        }
        .ant-pagination-item-active{
            background: #3E78ED;
            >a{
                color: white;
            }
        }

    }

    .fabu {
        .vant-modal-header {
            background: #F8F8F8;
        }

        .ant-btn {
            width: 91px;
            height: 40px;
        }
    }

}

.hh_modal_1 {
    .ant-modal-confirm-title {
        width: 100%;
    }

    .ant-modal-body {
        padding: 25px;
        min-height: 219px;
    }

    .ant-modal-confirm-body-wrapper {
        margin-top: 35px;
    }

    .ant-modal-confirm-body>.anticon {
        display: none;
    }

    .ant-btn {
        width: 91px;
        height: 40px;
        border-radius: 5px;
    }

    .ant-modal-confirm-btns {
        margin-top: 60px;
    }
}
@primary-color: #3E78ED;@text-color: #333333;@text-color-secondary: #888888;